import { firestore } from './firebaseInit';
import {
  collection,
  query,
  getDocs,
  where,
  limit,
  doc,
  setDoc,
} from 'firebase/firestore';

export const getUsersMenu = async (user) => {
  const collectionRef = collection(firestore, 'menu');

  let q;
  if (!user.group) {
    q = query(collectionRef, where('created_by', '==', user.id), limit(1));
  } else {
    q = query(collectionRef, where('group', '==', user.group), limit(1));
  }

  try {
    const querySnapshot = await getDocs(q);
    const doc = querySnapshot.docs?.pop();
    if (doc) {
      return {
        id: doc.id,
        ...doc.data(),
      };
    } else {
      return null;
    }
  } catch (error) {
    handleFirestoreError(error);
    return null;
  }
};

/* Switch date, day and week between two menu items*/
export const updateMenu = async (alteredMenu) => {
  let menuRef = doc(firestore, 'menu', alteredMenu.id);
  try {
    await setDoc(menuRef, alteredMenu, { merge: false });
  } catch (error) {
    handleFirestoreError(error);
    return undefined;
  }
  return alteredMenu;
};

const handleFirestoreError = (error) => {
  console.error('Firestore error:', error);
  // Add error handling logic here, such as logging the error to a database or sending an email notification to the app's administrator.
};
