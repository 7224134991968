import { MENU_INITIAL_STATE, menuReducer } from './menu.reducer';
import MENU_ACTION_TYPES from './menu.types';
import { functions } from '../../utils/firebase/firebaseInit';

import { useUser } from '../user/user.context';
import { httpsCallable } from 'firebase/functions';
import {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { getUsersMenu, updateMenu } from '../../utils/firebase/firebase.menu';
import { useSnackbar } from '../../hooks/useSnackbar';
import { useCallback } from 'react';

const MenuContext = createContext(MENU_INITIAL_STATE);

const generateMenuFunction = httpsCallable(functions, 'generateMenu');

export const MenuProvider = ({ children }) => {
  const { showError } = useSnackbar();
  const { user, userAuth } = useUser();
  const [{ menu, error, activeWeek, isLoading }, dispatch] = useReducer(
    menuReducer,
    MENU_INITIAL_STATE,
    undefined
  );

  const setMenu = useCallback(
    async (menu) => {
      dispatch({ type: MENU_ACTION_TYPES.FETCH_MENU, payload: menu });
    },
    [dispatch]
  );

  const getMenu = async () => {
    if (user) {
      dispatch({ type: MENU_ACTION_TYPES.MENU_ACTION_START });
      const menu = await getUsersMenu(user);

      if (menu) {
        let today = new Date();
        let endDate = new Date(menu.endDate);
        if (endDate >= today) {
          await setMenu(menu);
        } else {
          await setMenu(null);
        }
      } else {
        dispatch({ type: MENU_ACTION_TYPES.MENU_ACTION_DONE });
      }
    }
  };

  const getMenuItem = (dishid) => {
    if (menu) {
      for (let menuWeek of menu.menuWeeks) {
        for (const item of menuWeek.menuItems) {
          if (item.id === dishid) {
            return item;
          }
        }
      }
    } else {
      return undefined;
    }
  };

  const switchMenuItems = async (dishIds) => {
    const updatedMenu = { ...menu };

    // Find the dishes in the menu
    const dishes = dishIds
      .flatMap((id) =>
        updatedMenu.menuWeeks.flatMap((week) =>
          week.menuItems.find((item) => item.id === id)
        )
      )
      .filter((dish) => dish !== undefined);

    if (dishes.length !== 2) {
      return;
    }

    // Create a new menu with the updated dishes
    updatedMenu.menuWeeks = updatedMenu.menuWeeks.map((week) => {
      const updatedWeek = {
        ...week,
        menuItems: week.menuItems.filter(
          (item) => item.id !== dishes[0].id && item.id !== dishes[1].id
        ),
      };
      let currentWeek = week.menuItems.find((x) => x !== undefined);
      if (currentWeek.week === dishes[0].week) {
        updatedWeek.menuItems.push({
          ...dishes[1],
          week: dishes[0].week,
          at_date: dishes[0].at_date,
          day: dishes[0].day,
        });
      }
      if (currentWeek.week === dishes[1].week) {
        updatedWeek.menuItems.push({
          ...dishes[0],
          week: dishes[1].week,
          at_date: dishes[1].at_date,
          day: dishes[1].day,
        });
      }
      return updatedWeek;
    });

    sortMenuItems(updatedMenu);

    // Update the menu and set the new state simultaneously
    await Promise.all([updateMenu(updatedMenu), setMenu(updatedMenu)]);
  };

  const sortMenuItems = (menu) => {
    menu.menuWeeks.forEach((week) => {
      week.menuItems.sort((a, b) => a.day - b.day);
    });
  };

  const generateMenu = async (options) => {
    dispatch({ type: MENU_ACTION_TYPES.GENERATE_MENU });
    let startDate = new Date(options.start_date);
    options.start_date = startDate.toDateString();
    //TODO remove this when fish days option is implemented
    options.fish_days = false;
    await generateMenuFunction({
      userId: userAuth.uid,
      auth: userAuth.auth.auth,
      options: options,
    })
      .then((result) => {
        // Read result of the Cloud Function.
        const data = result.data;
        if (data.success) {
          dispatch({
            type: MENU_ACTION_TYPES.GENERATE_MENU_DONE,
            payload: data,
          });
          getMenu();
        } else {
          showError(data.message);
          dispatch({ type: MENU_ACTION_TYPES.MENU_ACTION_DONE });
        }
      })
      .catch((error) => {
        console.log(error);
        let errorMess =
          'Something went wrong, contact the site admin or try again later -- ' +
          error.name +
          ' ' +
          error.message;
        showError(errorMess);
        dispatch({ type: MENU_ACTION_TYPES.MENU_ACTION_DONE });
      });
  };

  const clearMenuContext = () => {
    setMenu(null);
  };

  useEffect(() => {
    getMenu();
  }, [user]);

  const contextValue = useMemo(
    () => ({
      menu,
      activeWeek,
      isLoading,
      error,
      generateMenu,
      getMenuItem,
      switchMenuItems,
      getMenu,
      clearMenuContext,
    }),
    [
      menu,
      activeWeek,
      isLoading,
      error,
      generateMenu,
      getMenuItem,
      switchMenuItems,
      getMenu,
    ]
  );

  return (
    <MenuContext.Provider value={contextValue}>{children}</MenuContext.Provider>
  );
};

export const useMenu = () => useContext(MenuContext);
