import React, { Fragment } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Checkbox from '@mui/material/Checkbox';
import Avatar from '@mui/material/Avatar';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const MealList = ({ meals, setViewedMeal, handleToggle }) => {
  return (
    <Fragment>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant='h6'>Välj måltider</Typography>
          </Grid>
        </Grid>
        <List dense>
          {meals.map((meal) => {
            const labelId = `checkbox-list-secondary-label-${meal.id}`;
            return (
              <ListItem key={meal.id} disablePadding>
                <Checkbox
                  id={meal.id}
                  edge='start'
                  onChange={handleToggle(meal)}
                  checked={meal.useMeal}
                  inputProps={{ 'aria-labelledby': labelId }}
                  icon={<PanoramaFishEyeIcon sx={{ color: grey[500] }} />}
                  checkedIcon={<TaskAltIcon sx={{ color: grey[500] }} />}
                />
                <ListItemButton onClick={() => setViewedMeal(meal.id)}>
                  <ListItemText id={labelId} primary={meal.title} />
                  <ListItemAvatar>
                    <Avatar alt={meal.title} src={meal.imageUrl} />
                  </ListItemAvatar>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </Fragment>
  );
};

export default MealList;
