import { MealsProvider } from './meals/meals.context';
import { MenuProvider } from './menu/menu.context';
import { UserProvider } from './user/user.context';

export default function Providers({ children, }) {

  return (
    <UserProvider>
      <MenuProvider>
        <MealsProvider>{children}</MealsProvider>
      </MenuProvider>
    </UserProvider>
  );

}
