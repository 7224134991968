import food from '../../assets/food.jpeg';
import { Typography, Paper, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import { Outlet } from 'react-router-dom';

const Home = () => {

  return (
    <Box sx={{ paddingTop: '1rem', }}>
      <Paper
        sx={{
          position: 'relative',
          backgroundColor: 'grey.800',
          color: '#fff',
          mb: 4,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${food})`,
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: 'rgba(0,0,0,.3)',
          }}
        />
        <Grid container>
          <Grid item md={6}>
            <Box
              sx={{
                position: 'relative',
                p: { xs: 3, md: 6, },
                pr: { md: 0, },
              }}
            >
              <Typography component="h1" variant="h3" color="inherit" gutterBottom>
                Skapa din egen veckomeny
              </Typography>
              <Typography variant="h5" color="inherit" paragraph>
                Skapa dina måltider, välj hur många dagar och veckor menyn ska spänna över och generera en veckomeny.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      <Outlet />
    </Box>
  );

};

export default Home;
