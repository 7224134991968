import {
  signInWithGooglePopup,
  signInAuthWithEmailAndPassword,
} from '../../utils/firebase/firebase.utils';
import { Google } from '@mui/icons-material';
import { Box, Container, Button, TextField, Grid, Link } from '@mui/material';
import { React } from 'react';
import logo from '../../assets/logo192.png';
import { useSnackbar } from '../../hooks/useSnackbar';

export default function Login() {
  const { showInfo } = useSnackbar();

  const handleLogin = async (event) => {
    event.preventDefault();
    try {
      const data = new FormData(event.currentTarget);
      let email = data.get('email');
      let password = data.get('password');
      await signInAuthWithEmailAndPassword(email, password);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Container maxWidth='xs'>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: {
            md: 4,
            xs: 0,
          },
          marginBottom: 4,
          paddingTop: '6rem',
        }}
      >
        <Box
          sx={{
            width: 128,
            height: 128,
          }}
        >
          <img
            style={{ maxWidth: '100%', objectFit: 'fill' }}
            src={logo}
            alt='MenuMaker'
          />
        </Box>
      </Box>

      <Box
        mt={2}
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Button
          variant='contained'
          color='primary'
          startIcon={<Google />}
          onClick={signInWithGooglePopup}
        >
          Logga in med Google
        </Button>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginY: 2,
        }}
      >
        <Box
          sx={{
            width: 96,
          }}
        >
          <img style={{ maxWidth: '100%' }} src='' alt='' />
        </Box>
      </Box>
      <Box component='form' onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
        <TextField
          margin='normal'
          required
          fullWidth
          id='email'
          label='Epost'
          name='email'
          autoComplete='email'
          autoFocus
        />
        <TextField
          margin='normal'
          required
          fullWidth
          name='password'
          label='Lösenord'
          type='password'
          id='password'
          autoComplete='current-password'
        />
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ mt: 3, mb: 2 }}
        >
          Logga in
        </Button>
        <Grid container>
          <Grid item xs>
            <Link
              href='#'
              variant='body2'
              onClick={() =>
                showInfo('Har du glömt lösenordet, kontakta site admin')
              }
            >
              Glömt lösenordet?
            </Link>
          </Grid>
          <Grid item>
            <Link
              href='#'
              variant='body2'
              onClick={() =>
                showInfo(
                  'Tala med site admin för att få ett konto eller registrera dig med google'
                )
              }
            >
              {'Har du inget konto? Registrera dig'}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
