import React, { Fragment } from 'react';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material';
import './profile_styles.css';
import { useUser } from '../../providers/user/user.context';

const ProfileCard = ({ user }) => {
  const { palette } = useTheme();

  return (
    <Fragment>
      <Box
        sx={{
          p: 2,
          gridTemplateColumns: { md: '1fr' },
          gap: 2,
          textAlign: 'center',
        }}
        display='flex'
        flexDirection='column'
        justifyContent='center'
        alignItems='center'
        minHeight='10vh'
      >
        <Card
          sx={{
            borderRadius: 12,
            minWidth: 256,
            textAlign: 'center',
          }}
        >
          <CardContent>
            <Avatar
              src={user.photoURL}
              sx={{ width: 60, height: 60, margin: 'auto' }}
            />
            <Typography
              sx={{
                fontSize: 18,
                fontWeight: 'bold',
                letterSpacing: '0.5px',
                marginTop: 8,
                marginBottom: 0,
              }}
            >
              {user.displayName}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                color: palette.grey[500],
                marginBottom: '0.875em',
              }}
            >
              {user.email}
            </Typography>
          </CardContent>
          <Divider light />
          <Box display={'flex'}>
            <Box p={2} flex={'auto'}>
              <p className='statLabel'>Grupp</p>
              <p className='statValue'>{user.group}</p>
            </Box>
          </Box>
        </Card>
      </Box>
    </Fragment>
  );
};

export default ProfileCard;
