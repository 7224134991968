import App from './App';
import Providers from './providers';
import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { Snackbar } from './components/snackbar/snackbar';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <SnackbarProvider
      components={{
        success: Snackbar,
        warning: Snackbar,
        info: Snackbar,
        default: Snackbar,
        error: Snackbar,
      }}
      autoHideDuration={6000}
      maxSnack={2}
    >
      <Providers>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Providers>
    </SnackbarProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
