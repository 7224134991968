import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import React from 'react';

const Spinner = () => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size='6rem' />
    </Box>
  );
};

export default Spinner;
