import {
  createUserDocumentFromAuth,
  onAuthStateChangedListener,
} from '../../utils/firebase/firebase.utils.js';
import { createContext, useEffect, useReducer, useContext } from 'react';

export const UserContext = createContext({
  user: null,
  setUser: () => null,
  userAuth: null,
  setUserAuth: () => null,
  userIsLoading: false,
  setUserIsLoading: () => null,
  clearUserContext: () => null,
});

export const USER_ACTION_TYPES = {
  SET_USER_AUTH: 'SET_USER_AUTH',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  USER_IS_LOADING: 'USER_IS_LOADING',
};

const userReducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case USER_ACTION_TYPES.SET_USER_AUTH:
      return {
        ...state,
        userAuth: payload,
      };
    case USER_ACTION_TYPES.SET_CURRENT_USER:
      return {
        ...state,
        user: payload,
      };
    case USER_ACTION_TYPES.USER_IS_LOADING:
      return {
        ...state,
        userIsLoading: payload,
      };
    default:
      throw new Error(`Unhandled tyo ${type} in userReducer`);
  }
};

const INITIAL_STATE = {
  user: null,
  userAuth: null,
  userIsLoading: false,
};

export const UserProvider = ({ children }) => {
  const [{ user, userAuth, userIsLoading }, dispatch] = useReducer(
    userReducer,
    INITIAL_STATE
  );

  const setUserAuth = (userAuth) => {
    dispatch({ type: USER_ACTION_TYPES.SET_USER_AUTH, payload: userAuth });
  };

  const setUser = (user) => {
    dispatch({ type: USER_ACTION_TYPES.SET_CURRENT_USER, payload: user });
  };

  const setUserIsLoading = (loading) => {
    dispatch({ type: USER_ACTION_TYPES.USER_IS_LOADING, payload: loading });
  };

  const clearUserContext = () => {
    setUser(null);
    setUserAuth(null);
  };

  const value = {
    userAuth,
    user,
    setUserAuth,
    userIsLoading,
    setUserIsLoading,
    clearUserContext,
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChangedListener((user) => {
      if (user) {
        const createOrGetUser = async () => {
          let res = await createUserDocumentFromAuth(user);
          setUser(res);
        };
        createOrGetUser();
      }
      setUserAuth(user);
    });

    return unsubscribe;
  }, []);

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
