import { initializeApp } from 'firebase/app';
import { connectAuthEmulator, getAuth } from 'firebase/auth';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import {
  connectFirestoreEmulator,
  getFirestore,
  initializeFirestore,
} from 'firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions';
import { connectStorageEmulator, getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
  appId: process.env.REACT_APP_FIREBASE_appId,
  measurementId: process.env.REACT_APP_FIREBASE_measurementId,
};
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
if (process.env.REACT_APP_EMULATE_AUTH === 'true') {
  console.info('Using auth emulator');
  connectAuthEmulator(auth, 'http://localhost:9099');
}
//export const analytics = getAnalytics(app);
export const functions = getFunctions(app, 'europe-west1');

if (process.env.REACT_APP_EMULATE_FUNCTIONS === 'true') {
  console.info('Using functions emulator');
  connectFunctionsEmulator(functions, 'localhost', 5001);
}

initializeFirestore(app, {
  ignoreUndefinedProperties: true,
});

export const firestore = getFirestore(app);
if (process.env.REACT_APP_EMULATE_FIRESTORE === 'true') {
  console.info('Using firestore emulator');
  connectFirestoreEmulator(firestore, 'localhost', 8080);
}
export const database = getDatabase(app);
if (process.env.REACT_APP_EMULATE_DATABASE === 'true') {
  console.info('Using realtime database emulator');
  connectDatabaseEmulator(database, 'localhost', 8080);
}
export const storage = getStorage(app);
if (process.env.REACT_APP_EMULATE_STORAGE === 'true') {
  console.info('Using storage emulator');
  connectStorageEmulator(storage, 'localhost', 9199);
}
