import React from 'react';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Input from '@mui/material/Input';

const MealSort = ({ sortType, setSortType }) => {
  const sortTypeOptions = [
    { value: 'time', label: 'Senast tillagd' },
    { value: 'alphabetical', label: 'A till Ö' },
  ];
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', pt: 1 }}>
      <Typography sx={{ mr: 1 }}>Sortera efter</Typography>
      <FormControl variant='standard' sx={{ mr: 2, minWidth: 120 }}>
        <Select
          MenuProps={{ variant: 'menu' }}
          labelId='sort-type-label'
          id='sort-type'
          value={sortType}
          label='Sortera efter'
          onChange={(event) => setSortType(event.target.value)}
          input={
            <Input label={sortType} id={'multiple-checkbox-' + sortType} />
          }
        >
          {sortTypeOptions.map((option) => (
            <MenuItem key={option.value} value={option.value} id={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default MealSort;
