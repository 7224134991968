const MEALS_ACTION_TYPES = {
  MEAL_ACTION_START: 'MEAL_ACTION_START',
  MEAL_ACTION_FAILED: 'MEAL_ACTION_FAILED',
  CREATE_MEAL: 'CREATE_MEAL',
  FETCH_MEALS: 'FETCH_MEALS',
  DELETE_MEAL: 'DELETE_MEAL',
  FETCH_MEAL: 'FETCH_MEAL',
  EDIT_MEAL: 'EDIT_MEAL',
  FILTER_BY_VALUE: 'FILTER_BY_VALUE',
};

export default MEALS_ACTION_TYPES;
