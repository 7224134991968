import { useMeals } from '../../../providers/meals/meals.context';
import Delete from '@mui/icons-material/Delete';
import Edit from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function MealCard(props) {
  const navigate = useNavigate();
  const { deleteMeal } = useMeals();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { meal } = props;

  const [expanded, setExpanded] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const editItem = () => {
    setAnchorEl(null);
    navigate(`/meals/${meal.title}/${meal.id}`);
  };

  const handleDelete = () => {
    setAnchorEl(null);
    deleteMeal(meal.id);
    navigate('/meals');
  };

  const renderIcons = (meal) => {
    return (
      <React.Fragment>
        {meal.type.includes('Vanlig') ? (
          <Avatar
            aria-label='recipe'
            src='../../../images/meal-ordinary.png'
            alt='Vanlig'
            sx={{ width: 28, height: 28 }}
          ></Avatar>
        ) : null}
        {meal.type.includes('Fisk') ? (
          <Avatar
            aria-label='recipe'
            src='../../../images/meal-fish.png'
            alt='Fisk'
            sx={{ width: 28, height: 28 }}
          ></Avatar>
        ) : null}
        {meal.type.includes('Vegetarisk') ? (
          <Avatar
            aria-label='recipe'
            src='../../../images/meal-vegetarian.png'
            alt='Vegetarisk'
            sx={{ width: 28, height: 28 }}
          ></Avatar>
        ) : null}
        {meal.type.includes('Fredagsmiddag') ? (
          <Avatar
            aria-label='recipe'
            src='../../../images/meal-friday.png'
            alt='Fredags middag'
            sx={{ width: 28, height: 28 }}
          ></Avatar>
        ) : null}
        {meal.type.includes('Snabb') ? (
          <Avatar
            aria-label='recipe'
            src='../../../images/meal-quick.png'
            alt='Snabb'
            sx={{ width: 28, height: 28 }}
          ></Avatar>
        ) : null}
      </React.Fragment>
    );
  };

  return (
    <>
      <Card sx={{ maxWidth: 375 }}>
        <CardHeader
          avatar={renderIcons(meal)}
          action={
            <>
              <IconButton
                id='meal-card-button'
                aria-label='settings'
                aria-controls={open ? 'meal-card-menu' : undefined}
                aria-haspopup='true'
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id='meal-card-menu'
                aria-labelledby='meal-card-button'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 110,
                }}
              >
                <MenuItem onClick={editItem}>
                  <ListItemIcon>
                    <Edit fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Redigera</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleDelete}>
                  <ListItemIcon>
                    <Delete fontSize='small' />
                  </ListItemIcon>
                  <ListItemText>Ta bort</ListItemText>
                </MenuItem>
              </Menu>
            </>
          }
        />
        <CardMedia
          component='img'
          height='194'
          image={
            meal.imageUrl === '' || meal.imageUrl === undefined
              ? 'https://storage.cloud.google.com/random-meal-driv-1596380337534.appspot.com/general-image.png?authuser=0'
              : meal.imageUrl
          }
          alt={meal.title}
        />
        <CardContent>
          <Typography gutterBottom variant='h5' component='div'>
            {meal.title}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant='body2' color='text.secondary'>
              Tillagd: {meal.created_at}
            </Typography>
            {meal.recepieUrl && (
              <Link
                href={meal.recepieUrl}
                target='_blank'
                variant='body1'
                sx={{ textDecoration: 'none' }}
              >
                <Typography>Recept</Typography>
              </Link>
            )}
          </Box>

          <Typography
            variant='body2'
            color='text.secondary'
            sx={{
              whiteSpace: 'nowrap',
              width: '50%' /* IE6 needs any width */,
              overflow:
                'hidden' /* "overflow" value must be different from  visible"*/,
              oTextOverflow: 'ellipsis' /* Opera < 11*/,
              textOverflow: 'ellipsis',
              maxHeight: '100px',
              overflowY: 'auto',
            }}
          >
            {meal.description}
          </Typography>
        </CardContent>
        <CardActions disableSpacing sx={{ background: '' }}>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label='show more'
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </CardActions>
        <Collapse in={expanded} timeout='auto' unmountOnExit>
          <CardContent>
            <Typography paragraph>
              {meal.description ? meal.description : 'Ingen beskrivning ännu'}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    </>
  );
}
