import { useSnackbar as NSuseSnackbar } from 'notistack';

export function useSnackbar() {
  const nsSnackbar = NSuseSnackbar();
  return {
    ...nsSnackbar,
    showSuccess: (message) =>
      nsSnackbar.enqueueSnackbar(message, { variant: 'success' }),
    showError: (message) =>
      nsSnackbar.enqueueSnackbar(message, { variant: 'error' }),
    showInfo: (message) =>
      nsSnackbar.enqueueSnackbar(message, { variant: 'info' }),
    showWarning: (message) =>
      nsSnackbar.enqueueSnackbar(message, { variant: 'warning' }),
  };
}
