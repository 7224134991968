import MEALS_ACTION_TYPES from "./meals.types";
import * as _ from "lodash";

export const MEALS_INITIAL_STATE = {
  meals: [],
  isLoading: false,
  error: null,
};

export const mealsReducer = (state = MEALS_INITIAL_STATE, action) => {
  switch (action.type) {
    case MEALS_ACTION_TYPES.MEAL_ACTION_START:
      return { ...state, isLoading: true };
    case MEALS_ACTION_TYPES.MEAL_ACTION_FAILED:
      return { ...state, isLoading: false };
    case MEALS_ACTION_TYPES.FETCH_MEALS:
      return {
        ...state,
        meals: action.payload,
        isLoading: false,
      };
    case MEALS_ACTION_TYPES.CREATE_MEAL:
      return {
        meals: [...state.meals, action.payload],
        isLoading: false,
      };
    case MEALS_ACTION_TYPES.EDIT_MEAL:
      let newArr = _.map(state.meals, function (a) {
        return a.id === action.payload.id ? action.payload : a;
      });
      return {
        ...state,
        isLoading: false,
        meals: newArr,
      };
    case MEALS_ACTION_TYPES.DELETE_MEAL:
      return {
        ...state,
        meals: state.meals.filter((meal) => meal.id !== action.payload),
        isLoading: false,
      };
    case MEALS_ACTION_TYPES.FILTER_BY_VALUE:
    default:
      return state;
  }
};
