import React from 'react';
import { useDroppable } from '@dnd-kit/core';
import Box from '@mui/material/Box';

export function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });
  const style = {
    opacity: isOver ? 0.5 : 1,
  };

  return (
    <Box ref={setNodeRef} style={style}>
      {props.children}
    </Box>
  );
}
