import AddMealForm from './components/meals/meal-add-form/meal-add-form.component';
import Spinner from './components/spinner/spinner.component';
import { useUser } from './providers/user/user.context';
import Login from './components/login/login';
import Home from './routes/home/home.component';
import Meals from './routes/meals/meals.component';
import Navigation from './routes/navigation/navigation.component';
import MenuComponent from './routes/menu/menu.component';

import Container from '@mui/material/Container';
import { Route, Routes, Navigate } from 'react-router-dom';
import WebFont from 'webfontloader';

import './css/app.css';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './hooks/useTheme';
import { useEffect } from 'react';
import DateFnsProvider from './providers/datefns/DatefnsProvider';
import UserProfile from './components/user/user-profile.component';

const App = () => {
  const { userAuth, userIsLoading } = useUser();

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Sacramento'],
      },
    });
  }, []);

  if (userIsLoading) {
    return <Spinner />;
  }
  if (!userAuth) {
    return (
      <DateFnsProvider>
        <ThemeProvider theme={theme}>
          <Container>
            <Routes>
              <Route path='/' element={<Navigation />}>
                <Route index element={<Login />} />
                <Route path='*' element={<Navigate to='/' replace />} />
              </Route>
            </Routes>
          </Container>
        </ThemeProvider>
      </DateFnsProvider>
    );
  }
  return (
    <DateFnsProvider>
      <ThemeProvider theme={theme}>
        <Container>
          <Routes>
            <Route path='/' element={<Navigation />}>
              <Route path='/' element={<Home />} />
              <Route path='meals/*' element={<Meals />} />
              <Route path='menu/*' element={<MenuComponent />} />
              <Route path='createmeal' element={<AddMealForm />} />
              <Route path='profile' element={<UserProfile />} />
            </Route>
          </Routes>
        </Container>
      </ThemeProvider>
    </DateFnsProvider>
  );
};

export default App;
