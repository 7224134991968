import React, { Fragment } from 'react';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';

const MealFilter = ({
  data,
  filterAddCallback,
  name,
  setData,
  filteredData,
  disabled,
}) => {
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setData(value);
    filterAddCallback(value);
  };
  return (
    <Fragment>
      <FormControl sx={{ width: 300 }}>
        <InputLabel htmlFor={'multiple-checkbox-' + name}>{name}</InputLabel>
        <Select
          disabled={disabled}
          labelId='multiple-checkbox-label'
          id='multiple-checkbox'
          multiple
          value={filteredData}
          onChange={handleChange}
          input={
            <OutlinedInput id={'multiple-checkbox-' + name} label={name} />
          }
          renderValue={(selected) => selected.join(', ')}
        >
          {data.map((type) => (
            <MenuItem key={type} value={type}>
              <Checkbox checked={filteredData.indexOf(type) > -1} />
              <ListItemText primary={type} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Fragment>
  );
};

export default MealFilter;
