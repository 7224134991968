import { firestore } from './firebaseInit';
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  Timestamp,
  where,
} from 'firebase/firestore';

/*
Fetches every meal in from the database.
This should only be used by admins
*/
export const getAllMealsAndDocuments = async () => {
  const collectionRef = collection(firestore, 'meals');
  const q = query(collectionRef);

  const querySnapshot = await getDocs(q);

  return querySnapshot.docs.map((docSnapshot) => {
    return {
      id: docSnapshot.id,
      created_at: docSnapshot.data().Created_date.toDate().toLocaleDateString(),
      ...docSnapshot.data(),
    };
  });
};

//Fetches the meals created by the user or meals belonging to the users group
export const getUsersMealsAndDocuments = async (userId, usersGroup) => {
  const collectionRef = collection(firestore, 'meals');

  let q;
  if (!usersGroup) {
    q = query(
      collectionRef,
      where('userId', '==', userId),
      orderBy('Created_date', 'desc')
    );
  } else {
    q = query(collectionRef, where('group', '==', usersGroup));
  }

  const querySnapshot = await getDocs(q);

  if (querySnapshot.empty) {
    return [];
  }

  return querySnapshot.docs.map((docSnapshot) => {
    return {
      id: docSnapshot.id,
      created_at: docSnapshot.data().Created_date.toDate().toLocaleDateString(),
      ...docSnapshot.data(),
    };
  });
};

export const createMealDocument = async (values) => {
  const data = {
    ...values,
    Created_date: new Date(),
    useMeal: true,
  };
  //if meal do not exists
  if (!values.id) {
    const mealRef = await addDoc(collection(firestore, 'meals'), data);
    const docSnap = await getDoc(mealRef);
    return { id: docSnap.id, ...docSnap.data() };
  }
  let mealRef;
  try {
    const docRef = doc(firestore, 'meals', values.id);
    mealRef = setDoc(docRef, data, {
      merge: true,
    });
  } catch (error) {
    console.log(error);
  }
  return mealRef;
};

export const updateMealDocument = async (values) => {
  try {
    const docRef = doc(firestore, 'meals', values.id);
    await setDoc(
      docRef,
      {
        ...values,
        updated_at: Timestamp.fromDate(new Date()),
      },
      {
        merge: true,
      }
    );
  } catch (error) {
    console.log(error);
    return undefined;
  }
  return values;
};

export const deleteMealDocument = async (mealId) => {
  try {
    const docRef = doc(firestore, 'meals', mealId);
    await deleteDoc(docRef);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};
