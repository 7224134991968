import MealCard from '../meal-card/meal-card.component';
import MealFilter from '../meal-filter/meal-filter.component';
import AddCardIcon from '@mui/icons-material/AddCard';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import MealSort from '../meal-sort/meal-sort.component';
import React, { useEffect, useState, Fragment } from 'react';
import MealList from '../meal-list/meal-list.component';
import { useMeals } from '../../../providers/meals/meals.context';
import ExtendedMealView from '../meal-extended-view/meal-extended-view.component';
import { useNavigate } from 'react-router-dom';

import { isBrowser, isMobile } from 'react-device-detect';

const MealBaseView = ({ meals }) => {
  const navigate = useNavigate();
  const { batchUpdateMeals } = useMeals();
  const [loading, setLoading] = useState(true);
  const types = ['Vanlig', 'Fisk', 'Vegetarisk', 'Fredagsmiddag', 'Snabb'];
  const appliedFilters = [];
  const [filteredMeals, setFilteredMeals] = useState([]);
  const [filteredTypes, setFilteredTypes] = useState([]);
  const [filteredMealSearchData, setFilteredMealSearchData] = useState(meals);
  const [typesDisabled, setTypesDisabled] = useState(false);
  const [sortType, setSortType] = useState('time');
  const [allMeals, setAllMeals] = useState([]);
  const [chosenMealForExtendedView, setChosenMealForExtendedView] =
    useState(null);

  let emptyMeal = {
    id: '',
    title: '',
    description: '',
    type: [],
    ingredients: [],
    instructions: [],
    useMeal: false,
    changed: false,
  };

  useEffect(() => {
    let allMeals = [];
    if (meals) {
      meals.forEach((meal) => {
        allMeals.push({
          ...meal,
          changed: false,
        });
      });
    }
    // sort the meals by created date
    allMeals = allMeals.sort((meal1, meal2) =>
      meal1.created_at < meal2.created_at
        ? 1
        : meal2.created_at < meal1.created_at
        ? -1
        : 0
    );
    setAllMeals(allMeals);
    setFilteredMeals(allMeals);
    setChosenMealForExtendedView(
      allMeals[0] === undefined ? emptyMeal : allMeals[0]
    );
    setLoading(false);
  }, [meals]);

  // function to set the viewed meal
  const setViewedMeal = (mealId) => {
    if (isMobile) {
      navigate(`/meals/${mealId}`);
    } else {
      setChosenMealForExtendedView(allMeals.find((meal) => meal.id === mealId));
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option) => option.title,
  });

  const filterOnTypes = (meal, value) => {
    return meal.type.some((t) => value.includes(t));
  };

  const filterOnTypeCallback = (value) => {
    if (value.length === 0) {
      setFilteredMeals(meals);
    } else {
      setFilteredMeals(meals.filter((meal) => filterOnTypes(meal, value)));
    }
  };

  const filterOnMealCallback = (value) => {
    if (!value) {
      setFilteredMeals(meals);
      setTypesDisabled(false);
    } else {
      setFilteredMeals(meals.filter((meal) => meal.title === value.title));
      setTypesDisabled(true);
    }
  };

  const typeFilter = {
    name: 'Typ av måltid',
    data: types,
    filteredData: filteredTypes,
    setData: (value) => setFilteredTypes(value),
    filterAddCallback: filterOnTypeCallback,
  };

  const mealSearchFilter = {
    name: 'Sök måltid',
    data: filteredMeals,
    filteredData: filteredMealSearchData,
    filterAddCallback: filterOnMealCallback,
  };

  //function that sets every meal in the list as checked when the button is pressed
  const handleCheckAll = () => {
    let newMeals = filteredMeals.map((checkedMeal) => {
      checkedMeal.useMeal = true;
      checkedMeal.changed = true;
      return checkedMeal;
    });
    setFilteredMeals(newMeals);
  };

  //function that unchecks every meal in the list when the button is pressed
  const handleUncheckAll = () => {
    let newMeals = filteredMeals.map((checkedMeal) => {
      checkedMeal.useMeal = false;
      checkedMeal.changed = true;
      return checkedMeal;
    });
    setFilteredMeals(newMeals);
  };
  //function that toggles the check on a meal and updates the list
  const handleToggle = (meal) => () => {
    meal.useMeal = !meal.useMeal;
    meal.changed = !meal.changed;
    let newMeals = filteredMeals.map((checkedMeal) =>
      checkedMeal.id === meal.id ? meal : checkedMeal
    );
    setFilteredMeals(newMeals);
  };

  //Find all meals in the meals list and update them in the database
  const saveMeals = () => {
    //filter out the meals from the meals list that exists in the checkedMeals list and add the check value to the meals list
    let mealsToUpdate = meals
      .map((meal) => {
        let checkedMeal = filteredMeals.find(
          (checkedMeal) => checkedMeal.id === meal.id
        );
        if (checkedMeal) {
          meal.useMeal = checkedMeal.useMeal;
        }
        if (checkedMeal && checkedMeal.changed) {
          return meal;
        }
      })
      .filter((meal) => meal !== undefined);
    //update the meals in the database
    batchUpdateMeals(mealsToUpdate);
  };

  appliedFilters.push(typeFilter);
  appliedFilters.push(mealSearchFilter);

  let sortedMeals = [...filteredMeals];
  if (sortType === 'time') {
    sortedMeals = sortedMeals.sort(
      (meal1, meal2) => meal2.Created_date - meal1.Created_date
    );
  } else if (sortType === 'alphabetical') {
    sortedMeals = sortedMeals.sort((meal1, meal2) => {
      return meal1.title.localeCompare(meal2.title);
    });
  }

  return (
    <Box>
      {loading ? (
        <CircularProgress color='success' />
      ) : (
        <>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            direction='row'
            justifyContent='start'
            alignItems='stretch'
            sx={{ paddingTop: '1rem', paddingBottom: '1rem' }}
          >
            <Grid item>
              <MealFilter
                disabled={typesDisabled}
                data={typeFilter.data}
                filteredData={typeFilter.filteredData}
                setData={typeFilter.setData}
                name={typeFilter.name}
                // @ts-ignore
                error={typeFilter.error}
                loading={typeFilter.loading}
                filterAddCallback={typeFilter.filterAddCallback}
                key={typeFilter.text}
              />
            </Grid>
            <Grid item>
              <Autocomplete
                id='mealsearch'
                options={mealSearchFilter.data}
                getOptionLabel={(option) => option.title}
                filterOptions={filterOptions}
                onChange={(event, newValue) => {
                  mealSearchFilter.filterAddCallback(newValue);
                }}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label={mealSearchFilter.name} />
                )}
              />
            </Grid>
            <Grid item>
              <Link to={'/createmeal'}>
                <IconButton>
                  <AddCardIcon fontSize='large' />
                </IconButton>
              </Link>
            </Grid>
          </Grid>
          <Divider variant='middle' />
          <Fragment>
            <Box>
              <MealSort sortType={sortType} setSortType={setSortType} />
            </Box>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              spacing={{ xs: 1, sm: 1, md: 1 }}
              sx={{ paddingTop: '2rem' }}
            >
              <Box
                sx={{
                  width: { xs: 'auto', sm: '60%' },
                  height: { xs: '40vh', sm: '65vh' },
                  overflowY: 'auto',
                  border: 'solid',
                  borderColor: 'grey.500',
                  borderWidth: '1px',
                  borderRadius: '10px',
                  padding: '1rem',
                }}
              >
                <MealList
                  meals={filteredMeals}
                  setViewedMeal={setViewedMeal}
                  handleToggle={handleToggle}
                />
              </Box>
              {isBrowser && (
                <Box
                  sx={{
                    width: { sm: '40%' },
                    height: { xs: '40vh', sm: '65vh' },
                  }}
                >
                  <ExtendedMealView meal={chosenMealForExtendedView} />
                </Box>
              )}
            </Stack>
            <Box sx={{ paddingTop: '0.5rem' }}>
              <Button
                onClick={() => handleCheckAll()}
                variant='text'
                color='primary'
              >
                Markera alla
              </Button>
              <Button
                onClick={() => handleUncheckAll()}
                variant='text'
                color='warning'
              >
                Avmarkera alla
              </Button>
              <Button
                onClick={() => saveMeals()}
                variant='text'
                color='success'
              >
                Spara
              </Button>
            </Box>
          </Fragment>
        </>
      )}
    </Box>
  );
};

export default MealBaseView;
