import { Alert } from '../alerts/alert';
import { Box } from '@mui/material';
import { SnackbarContent, useSnackbar } from 'notistack';
import React, { useCallback } from 'react';

export const Snackbar = function SuccessSnackbar(props, ref) {
  const { closeSnackbar } = useSnackbar();

  const {
    id,
    message,
    title,
    actions,
    variant,
    hideIconVariant,
    autoHideDuration,
    anchorOrigin,
    iconVariant,
    persist,
    ...other
  } = props;

  const onClose = useCallback(() => {
    if (id) {
      closeSnackbar(id);
    }
  }, [id]);

  return (
    <SnackbarContent ref={ref} role='alert' {...other}>
      <Box
        sx={{
          maxWidth: {
            sm: '37.25rem',
          },
          minWidth: {
            xs: '100%',
            md: '15rem',
          },
        }}
      >
        <Alert
          title={title}
          message={message}
          actions={actions}
          variant={other.variant}
          verticalMargin={false}
          onClose={onClose}
        />
      </Box>
    </SnackbarContent>
  );
};
