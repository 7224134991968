import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { setDefaultOptions } from 'date-fns';
import { sv } from 'date-fns/locale';

export default function DateFnsProvider({ children, ...props }) {
  const { dateAdapter, ...localizationProps } = props;
  setDefaultOptions({ locale: sv, weekStartsOn: 1 });
  return (
    <LocalizationProvider dateAdapter={dateAdapter || AdapterDateFns} {...localizationProps}>
      {children}
    </LocalizationProvider>
  );
}
