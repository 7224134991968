import { mealsReducer, MEALS_INITIAL_STATE } from './meals.reducer';
import MEALS_ACTION_TYPES from './meals.types';
import {
  getUsersMealsAndDocuments,
  createMealDocument,
  deleteMealDocument,
  updateMealDocument,
} from '../../utils/firebase/firebase.meals';
import {
  createContext,
  useEffect,
  useReducer,
  useContext,
  useMemo,
} from 'react';
import { useUser } from '../user/user.context';
import { useMenu } from '../menu/menu.context';
import { useSnackbar } from '../../hooks/useSnackbar';

const MealsContext = createContext({
  meals: [],
  isLoading: true,
  error: null,
  deleteMeal: () => null,
  updateMealAsync: () => null,
  createMeal: () => null,
  clearMealContext: () => null,
});

export const MealsProvider = ({ children }) => {
  const { user } = useUser();
  const { getMenu } = useMenu();
  const { showSuccess, showError } = useSnackbar();
  const [{ isLoading, meals, error }, dispatch] = useReducer(
    mealsReducer,
    MEALS_INITIAL_STATE,
    undefined
  );

  const setMeals = (mealsArray) => {
    dispatch({ type: MEALS_ACTION_TYPES.FETCH_MEALS, payload: mealsArray });
  };

  const createMeal = async (formValues) => {
    dispatch({ type: MEALS_ACTION_TYPES.MEAL_ACTION_START });
    try {
      const ret = await createMealDocument(formValues);
      if (!ret) {
        dispatch({ type: MEALS_ACTION_TYPES.MEAL_ACTION_FAILED });
      } else {
        dispatch({ type: MEALS_ACTION_TYPES.CREATE_MEAL, payload: ret });
      }
    } catch (error) {
      dispatch({ type: MEALS_ACTION_TYPES.MEAL_ACTION_FAILED });
    }
  };

  const deleteMeal = async (mealId) => {
    dispatch({ type: MEALS_ACTION_TYPES.MEAL_ACTION_START });
    try {
      if (!(await deleteMealDocument(mealId))) {
        dispatch({ type: MEALS_ACTION_TYPES.MEAL_ACTION_FAILED });
      } else {
        dispatch({ type: MEALS_ACTION_TYPES.DELETE_MEAL, payload: mealId });
      }
    } catch (error) {
      dispatch({ type: MEALS_ACTION_TYPES.MEAL_ACTION_FAILED });
    }
  };

  const updateMealAsync = async (formValues) => {
    console.log(formValues);
    dispatch({ type: MEALS_ACTION_TYPES.MEAL_ACTION_START });
    try {
      const ret = await updateMealDocument(formValues);
      if (!ret) {
        dispatch({ type: MEALS_ACTION_TYPES.MEAL_ACTION_FAILED });
      } else {
        dispatch({ type: MEALS_ACTION_TYPES.EDIT_MEAL, payload: ret });
        getMenu();
        showSuccess(
          'Uppdatering sparad. Du kan behöva ladda om sidan för att se förändringen i menyn.'
        );
      }
    } catch (error) {
      dispatch({ type: MEALS_ACTION_TYPES.MEAL_ACTION_FAILED });
    }
  };

  //Update all meals in the list
  const batchUpdateMeals = async (meals) => {
    dispatch({ type: MEALS_ACTION_TYPES.MEAL_ACTION_START });
    try {
      for (const meal of meals) {
        const ret = await updateMealDocument(meal);
        if (!ret) {
          dispatch({ type: MEALS_ACTION_TYPES.MEAL_ACTION_FAILED });
          showError('Något gick fel när måltiderna skulle sparas.');
          return false;
        }
      }
      const mealsArray = await getUsersMealsAndDocuments(user.id, user.group);
      setMeals(mealsArray);
    } catch (error) {
      dispatch({ type: MEALS_ACTION_TYPES.MEAL_ACTION_FAILED });
      showError('Något gick fel när måltiderna skulle sparas.');
    }
  };

  const clearMealContext = () => {
    setMeals(null);
  };

  useEffect(() => {
    if (user) {
      const getMeals = async () => {
        dispatch({ type: MEALS_ACTION_TYPES.MEAL_ACTION_START });
        try {
          const mealsArray = await getUsersMealsAndDocuments(
            user.id,
            user.group
          );
          setMeals(mealsArray);
        } catch (error) {
          dispatch({ type: MEALS_ACTION_TYPES.MEAL_ACTION_FAILED });
        }
      };
      getMeals();
    }
  }, [user]);

  // memoize the full context value
  const contextValue = useMemo(() => {
    return {
      meals,
      isLoading,
      error,
      createMeal,
      deleteMeal,
      updateMealAsync,
      clearMealContext,
      batchUpdateMeals,
    };
  }, [error, isLoading, meals]);

  return (
    <MealsContext.Provider value={contextValue}>
      {children}
    </MealsContext.Provider>
  );
};

export const useMeals = () => useContext(MealsContext);
