import MENU_ACTION_TYPES from './menu.types';
import { format } from 'date-fns';
import { sv } from 'date-fns/locale';

export const MENU_INITIAL_STATE = {
  menu: undefined,
  activeWeek: 1,
  isLoading: false,
  error: null,
  clearMenuContext: () => null,
};

export const menuReducer = (state = { menu: {}, activeWeek: 1 }, action) => {
  switch (action.type) {
    case MENU_ACTION_TYPES.MENU_ACTION_START:
      return {
        ...state,
        isLoading: true,
      };
    case MENU_ACTION_TYPES.MENU_ACTION_DONE:
      return {
        ...state,
        isLoading: false,
      };
    case MENU_ACTION_TYPES.FETCH_MENU:
      return {
        ...state,
        menu: action.payload,
        activeWeek: findCurrentWeek(action.payload),
        isLoading: false,
      };
    case MENU_ACTION_TYPES.GENERATE_MENU:
      return {
        ...state,
        isLoading: true,
      };
    case MENU_ACTION_TYPES.GENERATE_MENU_DONE:
      if (action.payload.success) {
        return {
          ...state,
          isLoading: false,
        };
      }
      return {
        ...state,
        isLoading: false,
        error: action.payload.message,
      };

    case MENU_ACTION_TYPES.UPDATE_ACTIVE_WEEK:
      return { ...state, activeWeek: action.payload };
    case MENU_ACTION_TYPES.UPDATE_MENUITEM_DATE:
      return {
        ...state,
      };
    default:
      return state;
  }
};

const findCurrentWeek = (menu) => {
  if (!menu) {
    return 1;
  }
  let currentDate = new Date();
  let currentWeekOfYear = format(currentDate, 'w', { locale: sv });
  let retWeek = 1;
  menu.menuWeeks.forEach((week) => {
    if (week.weekOfYear === currentWeekOfYear) {
      retWeek = week.menuItems[0].week;
    }
  });
  return retWeek;
};
