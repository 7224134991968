import AddMealForm from '../../components/meals/meal-add-form/meal-add-form.component';
import MealBaseView from '../../components/meals/meal-base-view/meal-base-view.component';
import ExtendedMealView from '../../components/meals/meal-extended-view/meal-extended-view.component';
import Spinner from '../../components/spinner/spinner.component';
import { useMeals } from '../../providers/meals/meals.context';
import React from 'react';
import { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';

const Meals = () => {
  const { meals, isLoading } = useMeals();

  return (
    <Fragment>
      {isLoading ? (
        <Spinner />
      ) : (
        <Routes>
          <Route
            index
            element={<MealBaseView meals={meals} isLoading={isLoading} />}
          />
          <Route path=':mealId' element={<ExtendedMealView />} />
          <Route path=':title/:mealId' element={<AddMealForm />} />
        </Routes>
      )}
    </Fragment>
  );
};

export default Meals;
