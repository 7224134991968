import { Paper, Typography, Box, IconButton } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import React from 'react';
import { format } from 'date-fns';
import * as _ from 'lodash';
import Image from 'mui-image';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

export function MenuCard({ menuitem }, props) {
  const { palette } = useTheme();
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: menuitem.id,
  });

  // Within your component that receives `transform` from `useDraggable`:
  const style = {
    transform: CSS.Translate.toString(transform),
  };

  let dayOfWeek = format(new Date(menuitem.at_date), 'EEEE');
  dayOfWeek = _.capitalize(dayOfWeek);

  let date = format(new Date(menuitem.at_date), 'd MMMM');

  return (
    <Box ref={setNodeRef} style={style}>
      <Paper
        variant='outlined'
        square
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          minHeight: '4rem',
          marginLeft: '0.5rem',
          marginRight: '0.5rem',
          marginBottom: '0.25rem',
          paddingLeft: '0.5rem',
          paddingRight: '0.5rem',
          color: palette.grey[400],
        }}
      >
        <Typography variant='h5' sx={{ alignSelf: 'center' }}>
          {dayOfWeek}
        </Typography>
        <IconButton sx={{ alignSelf: 'center' }} {...listeners} {...attributes}>
          <DragIndicatorIcon />
        </IconButton>
      </Paper>
      <Paper
        variant='outlined'
        square
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '10rem',
          marginLeft: '0.5rem',
          marginRight: '0.5rem',
          marginBottom: '0.5rem',
          padding: '0.5rem',
        }}
      >
        <Typography
          variant='h6'
          sx={{
            color: palette.grey[500],
            marginBottom: '0.5rem',
          }}
        >
          {date}
        </Typography>
        <Link
          to={`${menuitem.title}/${menuitem.id}`}
          style={{ textDecoration: 'none' }}
        >
          <Image
            src={
              menuitem.imageUrl === '' || menuitem.imageUrl === undefined
                ? 'https://storage.cloud.google.com/random-meal-driv-1596380337534.appspot.com/general-image.png?authuser=0'
                : menuitem.imageUrl
            }
            height='8rem'
            width='100%'
            fit='cover'
            duration={1000}
            easing='cubic-bezier(0.7, 0, 0.6, 1)'
            showLoading={true}
            errorIcon={true}
            shift={null}
            distance='10px'
            shiftDuration={300}
            bgColor='inherit'
            sx={{ borderRadius: '0.5rem' }}
          />
          <Typography
            variant='h6'
            sx={{
              color: palette.grey[800],
              marginTop: '0.5rem',
            }}
          >
            {menuitem.title}
          </Typography>
        </Link>
      </Paper>
    </Box>
  );
}
