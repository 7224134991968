import React, { Fragment, useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Edit from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';

import { useNavigate, useParams } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { useMeals } from '../../../providers/meals/meals.context';

const ExtendedMealView = ({ meal }) => {
  const [isLoading, setIsLoading] = useState(isMobile ? true : false);
  const navigate = useNavigate();
  const { meals } = useMeals();
  const { mealId } = useParams();
  const [currentMeal, setCurrentMeal] = useState(
    meal === undefined ? {} : meal
  );

  useEffect(() => {
    if (isMobile) {
      setIsLoading(true);
      if (mealId) {
        let newMeal = meals.find((meal) => meal.id === mealId);
        setCurrentMeal(newMeal);
        setIsLoading(false);
      }
    } else {
      setCurrentMeal(meal);
    }
  }, [isMobile, mealId, meals, meal]);

  const renderIcons = (meal) => {
    return (
      <React.Fragment>
        {meal.type.includes('Vanlig') ? (
          <Avatar
            aria-label='recipe'
            src='../../../images/meal-ordinary.png'
            alt='Vanlig'
            sx={{ width: 28, height: 28 }}
          ></Avatar>
        ) : null}
        {meal.type.includes('Fisk') ? (
          <Avatar
            aria-label='recipe'
            src='../../../images/meal-fish.png'
            alt='Fisk'
            sx={{ width: 28, height: 28 }}
          ></Avatar>
        ) : null}
        {meal.type.includes('Vegetarisk') ? (
          <Avatar
            aria-label='recipe'
            src='../../../images/meal-vegetarian.png'
            alt='Vegetarisk'
            sx={{ width: 28, height: 28 }}
          ></Avatar>
        ) : null}
        {meal.type.includes('Fredagsmiddag') ? (
          <Avatar
            aria-label='recipe'
            src='../../../images/meal-friday.png'
            alt='Fredags middag'
            sx={{ width: 28, height: 28 }}
          ></Avatar>
        ) : null}
        {meal.type.includes('Snabb') ? (
          <Avatar
            aria-label='recipe'
            src='../../../images/meal-quick.png'
            alt='Snabb'
            sx={{ width: 28, height: 28 }}
          ></Avatar>
        ) : null}
      </React.Fragment>
    );
  };

  const editItem = () => {
    navigate(`/meals/${currentMeal.title}/${currentMeal.id}`);
  };

  return (
    <Fragment>
      {isLoading ? (
        <CircularProgress color='success' />
      ) : (
        <Paper>
          <Card>
            <CardHeader
              avatar={renderIcons(currentMeal)}
              action={
                <IconButton id='edit-button' onClick={editItem}>
                  <Edit />
                </IconButton>
              }
            />
            <CardMedia
              component='img'
              height='200'
              image={
                currentMeal.imageUrl === '' ||
                currentMeal.imageUrl === undefined
                  ? 'https://storage.cloud.google.com/random-meal-driv-1596380337534.appspot.com/general-image.png?authuser=0'
                  : currentMeal.imageUrl
              }
            />
            <CardContent>
              <Box component='form'>
                <Typography gutterBottom variant='h5' component='div'>
                  {currentMeal.title}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant='body2' color='text.secondary'>
                    Tillagd: {currentMeal.created_at}
                  </Typography>
                  {currentMeal.recepieUrl && (
                    <Link
                      href={currentMeal.recepieUrl}
                      target='_blank'
                      variant='body1'
                      sx={{ textDecoration: 'none' }}
                    >
                      <Typography>Recept</Typography>
                    </Link>
                  )}
                </Box>
                <Divider />
                <Box
                  sx={{
                    paddingTop: '1rem',
                  }}
                  noValidate
                  autoComplete='off'
                >
                  <TextField
                    multiline
                    fullWidth
                    variant='standard'
                    width='100%'
                    minRows={3}
                    maxRows={22}
                    placeholder='Beskrivning'
                    value={currentMeal.description}
                  />
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Paper>
      )}
    </Fragment>
  );
};

export default ExtendedMealView;
