const MENU_ACTION_TYPES = {
  MENU_ACTION_START: 'MENU_ACTION_START',
  MENU_ACTION_DONE: 'MENU_ACTION_DONE',
  FILTER_BY_VALUE: 'FILTER_BY_VALUE',
  FETCH_MENU: 'FETCH_MENU',
  GENERATE_MENU: 'GENERATE_MENU',
  GENERATE_MENU_DONE: 'GENERATE_MENU_DONE',
  UPDATE_ACTIVE_WEEK: 'UPDATE_ACTIVEWEEK',
  UPDATE_MENUITEM_DATE: 'UPDATE_MENUITEM_DATE',
};

export default MENU_ACTION_TYPES;
