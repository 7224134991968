import { Timestamp } from 'firebase/firestore';

export type MealItem = {
  id: string;
  Created_date: Timestamp;
  description: string;
  group: string;
  recepieUrl: string;
  title: string | null;
  type: string[];
  userId: string;
  imageUrl: string;
  useMeal: boolean;
};

export type MenuItem = {
  at_date: string;
  day: number;
  week: number;
  mealId: string;
} & MealItem;

export type MenuWeek = {
  weekOfYear: string;
  weekStartDate: string;
  weekEndDate: string;
  menuItems: MenuItem[];
};

export type Menu = {
  created_by: string;
  group: string;
  startDate: string;
  endDate: string;
  menuWeeks: MenuWeek[];
};

export type MenuOptions = {
  week_option: number;
  day_option: number;
  start_date: string;
  friday_meal: boolean;
  fish_days: boolean;
};

export enum MealType {
  VANLIG = 'Vanlig',
  FISK = 'Fisk',
  VEGETARISK = 'Vegetarisk',
  FREDAGSMIDDAG = 'Fredagsmiddag',
  SNABB = 'Snabb',
}

export enum WeekDays {
  SUNDAY = 0,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

export type User = {
  id: string;
  displayName: string;
  email: string;
  group: string;
  photoURL: string;
};
