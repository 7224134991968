import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const MenuSubmenu = ({
  currentWeek,
  setGenerateNewMenu,
  handleSwitchChange,
  genBtnDisabled,
  setShowAll,
  showAll,
}) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [menuLock, setMenuLock] = useState(false);

  const handleMenuLock = () => {
    setMenuLock(!menuLock);
    handleSwitchChange();
  };

  return (
    <Stack
      direction={{ xs: 'column', sm: 'row' }}
      alignItems='center'
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        p: 1,
        m: 1,
        borderRadius: 1,
      }}
    >
      <Typography>
        Vecka {currentWeek.weekOfYear}: {currentWeek.weekStartDate} -{' '}
        {currentWeek.weekEndDate}
      </Typography>
      {matches && (
        <Stack direction={{ xs: 'column', sm: 'row' }} alignItems='center'>
          <FormControlLabel
            control={
              <Switch
                checked={showAll}
                onChange={() => setShowAll(!showAll)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label='Visa alla'
          />
          <FormControlLabel
            control={
              <Switch
                checked={menuLock}
                onChange={handleMenuLock}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
            label='Lås upp meny'
          />
          <Button
            variant='outlined'
            disabled={genBtnDisabled}
            onClick={() => setGenerateNewMenu(true)}
          >
            Generera ny meny
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default MenuSubmenu;
