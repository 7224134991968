import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import {
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DatePicker } from '@mui/x-date-pickers';

import DateFnsProvider from '../../providers/datefns/DatefnsProvider';

const MenuForm = ({ generateMenu, showCancelBtn, setGenerateNewMenu }) => {
  const { control, handleSubmit } = useForm({
    reValidateMode: 'onBlur',
  });

  const calculateDate = () => {
    const date = new Date();
    let foundMonday = false;
    while (!foundMonday) {
      // day 1 is Monday
      if (date.getDay() === 1) {
        foundMonday = true;
      } else {
        date.setDate(date.getDate() + 1);
      }
    }
    return date;
  };

  const [startDate, setStartDate] = useState(calculateDate(new Date()));

  const weekOptions = [
    { label: '1 vecka', id: 'one_week', value: 1 },
    { label: '2 veckor', id: 'two_weeks', value: 2 },
    { label: '3 veckor', id: 'three_weeks', value: 3 },
    { label: '4 veckor', id: 'four_weeks', value: 4 },
  ];

  const dayOptions = [
    { label: '3 dagar', id: 'three_day', value: 1 },
    { label: '5 dagar', id: 'five_days', value: 5 },
    { label: '7 dagar', id: 'seven_days', value: 7 },
  ];

  let initialFormValues = {
    week_option: 4,
    day_option: 5,
    start_date: startDate,
    friday_meal: true,
  };

  const handleOnSubmit = (formValues) => {
    generateMenu({
      week_option: formValues.week_option,
      day_option: formValues.day_option,
      start_date: formValues.start_date,
      friday_meal: formValues.friday_meal,
    });
  };

  return (
    <>
      <Paper>
        <Box
          px={3}
          py={2}
          component='form'
          onSubmit={handleSubmit(handleOnSubmit)}
        >
          <Typography variant='h4' align='center' gutterBottom>
            Du har ingen aktiv meny
          </Typography>
          <Typography variant='h6' align='center' margin='dense'>
            Generera ny meny
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              {' '}
              <Controller
                control={control}
                name='start_date'
                defaultValue={initialFormValues.start_date}
                render={({ field: { onChange, ...restField } }) => (
                  <DateFnsProvider>
                    <DatePicker
                      fullwidth
                      label='Start datum'
                      onChange={(event) => {
                        onChange(event);
                        setStartDate(event);
                      }}
                      renderInput={(params) => (
                        <TextField fullWidth {...params} />
                      )}
                      {...restField}
                    />
                  </DateFnsProvider>
                )}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Controller
                control={control}
                name='day_option'
                defaultValue={initialFormValues.day_option}
                render={({ field }) => (
                  <Select {...field} fullWidth>
                    {dayOptions.map((option) => {
                      return (
                        <MenuItem value={option.value} key={option.id}>
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <Controller
                control={control}
                name='week_option'
                defaultValue={initialFormValues.week_option}
                render={({ field }) => (
                  <Select {...field} fullWidth>
                    {weekOptions.map((option) => {
                      return (
                        <MenuItem value={option.value} key={option.id}>
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Controller
                control={control}
                defaultValue={initialFormValues.friday_meal}
                name='friday_meal'
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Switch {...field} defaultChecked value={field.value} />
                    }
                    label='Använd fredagsmiddag'
                  />
                )}
              />
            </Grid>
          </Grid>
          <Box
            mt={3}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              position: 'relative',
            }}
          >
            <Button type='submit' variant='contained' color='primary'>
              Generera ny meny
            </Button>
            {showCancelBtn && (
              <Button
                variant='contained'
                color='error'
                onClick={() => setGenerateNewMenu(false)}
              >
                Avbryt
              </Button>
            )}
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default MenuForm;
