import Spinner from '../../components/spinner/spinner.component';
import { useMenu } from '../../providers/menu/menu.context';
import React from 'react';
import { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';

import MenuList from '../../components/menu/menu-list.component';
import MenuForm from '../../components/menu/menu-form.component';
import MenuCardView from '../../components/menu/menu-card-view/menu-card-view.component';

const MenuComponent = () => {
  const { menu, isLoading, activeWeek, generateMenu, switchMenuItems } =
    useMenu();
  const menuExists = menu ? true : false;

  return (
    <Fragment>
      {isLoading ? (
        <Spinner />
      ) : menu ? (
        <Routes>
          <Route
            index
            element={
              <MenuList
                menu={menu}
                activeWeek={activeWeek}
                generateMenu={generateMenu}
                switchMenuItems={switchMenuItems}
              />
            }
          />
          <Route path=':title/:dishId' element={<MenuCardView />} />
        </Routes>
      ) : (
        <MenuForm
          generateMenu={generateMenu}
          showCancelBtn={menuExists}
          setGenerateNewMenu={undefined}
        />
      )}
    </Fragment>
  );
};

export default MenuComponent;
