import { Paper, Typography, Box } from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { format } from 'date-fns';
import * as _ from 'lodash';
import Image from 'mui-image';
import { useTheme } from '@mui/material/styles';
import { useParams } from 'react-router-dom';
import Link from '@mui/material/Link';
import Spinner from '../../spinner/spinner.component';
import { useMenu } from '../../../providers/menu/menu.context';

const MenuCardView = () => {
  const [menuitem, setMenuitem] = useState(undefined);
  const [date, setDate] = useState(undefined);
  const [dayOfWeek, setDayOfWeek] = useState(undefined);
  const { getMenuItem } = useMenu();
  const { palette } = useTheme();

  const { dishId } = useParams();

  useEffect(() => {
    let item = getMenuItem(dishId);
    if (item) {
      setMenuitem(item);
      let dayOfWeek = format(new Date(item.at_date), 'EEEE');
      dayOfWeek = _.capitalize(dayOfWeek);
      setDayOfWeek(dayOfWeek);

      let date = format(new Date(item.at_date), 'd MMMM');
      setDate(date);
    }
  }, [dishId]);

  return (
    <Fragment>
      {!menuitem ? (
        <Spinner />
      ) : (
        <Box>
          <Paper
            variant='outlined'
            square
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              minHeight: '4rem',
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
              marginBottom: '0.25rem',
              marginTop: '0.5rem',
              paddingLeft: '0.5rem',
              paddingRight: '0.5rem',
              color: palette.grey[400],
            }}
          >
            <Typography variant='h5' sx={{ alignSelf: 'center' }}>
              {dayOfWeek}
            </Typography>
          </Paper>
          <Paper
            variant='outlined'
            square
            sx={{
              display: 'flex',
              flexDirection: 'column',
              minHeight: '10rem',
              marginLeft: '0.5rem',
              marginRight: '0.5rem',
              marginBottom: '0.5rem',
              padding: '0.5rem',
            }}
          >
            <Typography
              variant='h6'
              sx={{
                color: palette.grey[500],
                marginBottom: '0.5rem',
              }}
            >
              {date}
            </Typography>
            <Image
              src={
                menuitem.imageUrl === '' || menuitem.imageUrl === undefined
                  ? 'https://storage.cloud.google.com/random-meal-driv-1596380337534.appspot.com/general-image.png?authuser=0'
                  : menuitem.imageUrl
              }
              height='18rem'
              width='100%'
              fit='cover'
              duration={1000}
              easing='cubic-bezier(0.7, 0, 0.6, 1)'
              showLoading={true}
              errorIcon={true}
              shift={null}
              distance='10px'
              shiftDuration={300}
              bgColor='inherit'
              sx={{ borderRadius: '0.5rem' }}
            />
            <Typography
              variant='h4'
              sx={{
                color: palette.grey[800],
                marginTop: '0.5rem',
              }}
            >
              {menuitem.title}
            </Typography>
            <Typography variant='h6'>Recept: </Typography>
            <Link href={menuitem.recepieUrl} target='_blank' variant='body2'>
              {menuitem.recepieUrl}
            </Link>
            <Typography variant='h6'>Beskrivning:</Typography>
            <Typography variant='body1'>{menuitem.description}</Typography>
          </Paper>
        </Box>
      )}
    </Fragment>
  );
};

export default MenuCardView;
