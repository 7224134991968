import React, { Fragment, useEffect, useState } from 'react';
import MenuForm from './menu-form.component';
import { Pagination } from '@mui/material';
import Box from '@mui/material/Box';
import '../../css/menu.custom.css';
import { MenuCard } from './menu-card/menu-card.component';
import MenuSubmenu from './menu-submenu/menu.submenu.component';
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { Droppable } from './Droppable';

const MenuList = ({ menu, activeWeek, generateMenu, switchMenuItems }) => {
  const [page, setPage] = useState(activeWeek);
  const [generateNewMenu, setGenerateNewMenu] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [allItems, setAllItems] = useState([]);
  const [currentWeek, setCurrentWeek] = useState(undefined);
  const [genBtnDisabled, setGenBtnDisabled] = useState(true);
  const [parent, setParent] = useState(null);

  const mouseSensor = useSensor(MouseSensor);
  const touchSensor = useSensor(TouchSensor);
  const keyboardSensor = useSensor(KeyboardSensor);

  const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);

  const numOfWeeks = menu.menuWeeks.length;

  function handleDragEnd(event) {
    const { over, active } = event;
    if (over.id !== active.id) {
      switchMenuItems([over.id, active.id]);
    }
    // If the item is dropped over a container, set it as the parent
    // otherwise reset the parent to `null`
    setParent(over ? over.id : null);
  }

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleSwitchChange = (event) => {
    setGenBtnDisabled(!genBtnDisabled);
  };

  useEffect(() => {
    let week = menu.menuWeeks.find(
      (week) => week.menuItems.at(0).week === page
    );
    setCurrentWeek(week);
    setParent(week.menuItems.at(0).id);
  }, [menu, page]);

  useEffect(() => {
    let arrayOfArrays = [];
    menu.menuWeeks.forEach((week) => {
      arrayOfArrays.push(week.menuItems);
    });

    const combinedArray = arrayOfArrays.reduce((accumulator, currentArray) => {
      return [...accumulator, ...currentArray];
    }, []);
    setAllItems(combinedArray);
  }, [menu]);

  return (
    <Fragment>
      {currentWeek && !generateNewMenu ? (
        <Fragment>
          <MenuSubmenu
            currentWeek={currentWeek}
            setGenerateNewMenu={setGenerateNewMenu}
            handleSwitchChange={handleSwitchChange}
            genBtnDisabled={genBtnDisabled}
            setShowAll={setShowAll}
            showAll={showAll}
          />
          <Box
            sx={{
              p: 2,
              gap: 1,
            }}
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            minHeight='40vh'
          >
            <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
              <Box
                sx={{
                  display: 'grid',
                  width: '100%',
                  gridTemplateColumns: 'repeat(auto-fill, minmax(8rem, 22rem))',
                }}
              >
                {!showAll
                  ? currentWeek.menuItems.map((menuitem) => {
                      return (
                        <Droppable key={menuitem.id} id={menuitem.id}>
                          <MenuCard key={menuitem.id} menuitem={menuitem} />
                        </Droppable>
                      );
                    })
                  : allItems.map((menuitem) => {
                      return (
                        <Droppable key={menuitem.id} id={menuitem.id}>
                          <MenuCard key={menuitem.id} menuitem={menuitem} />
                        </Droppable>
                      );
                    })}
              </Box>
            </DndContext>
          </Box>
          {!showAll && (
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                p: 1,
                m: 1,
                borderRadius: 1,
              }}
            >
              <Pagination
                page={page}
                count={numOfWeeks}
                onChange={handleChange}
                variant='outlined'
                shape='rounded'
              />
            </Box>
          )}
        </Fragment>
      ) : (
        <MenuForm
          generateMenu={generateMenu}
          showCancelBtn={true}
          setGenerateNewMenu={setGenerateNewMenu}
        />
      )}
    </Fragment>
  );
};

export default MenuList;
