import { useMeals } from '../../../providers/meals/meals.context';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import CardMedia from '@mui/material/CardMedia';
import Stack from '@mui/material/Stack';
import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { MealType } from '../../../utils/types/types.ts';
import { useUser } from '../../../providers/user/user.context';

const AddMealForm = () => {
  const navigate = useNavigate();
  const [type, setTypes] = useState([]);

  const { user } = useUser();
  const { meals, createMeal, updateMealAsync } = useMeals();

  const options = [
    { key: 'ordinary_meal', text: MealType.VANLIG },
    { key: 'fish_meal', text: MealType.FISK },
    { key: 'vegetarian_meal', text: MealType.VEGETARISK },
    { key: 'friday_meal', text: MealType.FREDAGSMIDDAG },
    { key: 'quick_meal', text: MealType.SNABB },
  ];

  let meal = {
    title: '',
    recepieUrl: '',
    description: '',
    type: ['Vanlig'],
    imageUrl: '',
  };

  const { mealId } = useParams();
  if (mealId) {
    meal = meals.find((meal) => meal.id === mealId);
    if (type.length === 0 && !meal.type.includes('meal')) {
      setTypes(meal.type);
    }
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (value.length === 0) {
      setTypes(['ordinary_meal']);
    } else {
      setTypes(
        // On autofill we get a stringified value.
        typeof value === 'string' ? value.split(',') : value
      );
    }
  };

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: meal,
  });

  const onSubmit = (data) => {
    let values = {
      group: user.group,
      userId: user.id,
      ...data,
    };
    if (mealId) {
      updateMealAsync(values);
    } else {
      createMeal(values);
    }
    navigate('/meals');
  };

  return (
    <>
      <Paper>
        <Box px={3} py={2}>
          <Typography variant='h4' align='center' margin='dense'>
            {mealId ? 'Redigera Måltid' : 'Lägg till ny måltid'}
          </Typography>
          <Stack direction={{ xs: 'column', sm: 'row' }}>
            <Box sx={{ width: '100%', p: 1 }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name='title'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        required
                        id='title'
                        label='Titel'
                        fullWidth
                        margin='dense'
                        {...register('title', {
                          required: 'Måltiden måste ha en titel',
                        })}
                        error={errors.title ? true : false}
                      />
                    )}
                  />
                  <Typography variant='inherit' color='textSecondary'>
                    {errors.title ? errors.title.message : ''}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={12}>
                  <Controller
                    name='description'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id='description'
                        label='Beskrivning'
                        fullWidth
                        multiline
                        margin='dense'
                        {...register('description')}
                        error={errors.description ? true : false}
                      />
                    )}
                  />
                  <Typography variant='inherit' color='textSecondary'>
                    {errors.description ? errors.description.message : ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name='recepieUrl'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id='recepieUrl'
                        label='Receptlänk'
                        fullWidth
                        multiline
                        margin='dense'
                        {...register('recepieUrl')}
                        error={errors.recepieUrl ? true : false}
                      />
                    )}
                  />
                  <Typography variant='inherit' color='textSecondary'>
                    {errors.recepieUrl ? errors.recepieUrl.message : ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Controller
                    name='type'
                    control={control}
                    render={({ field }) => (
                      <FormControl {...field} sx={{ my: 1, width: '100%' }}>
                        <InputLabel id='type'>Typ av måltid</InputLabel>
                        <Select
                          label='Typ av måltid'
                          labelId='type'
                          id='type'
                          multiple
                          value={type}
                          {...register('type')}
                          onChange={handleChange}
                          input={<OutlinedInput label='Typ av måltid' />}
                          renderValue={(selected) => selected.join(', ')}
                        >
                          {options.map((option) => (
                            <MenuItem key={option.key} value={option.text}>
                              <Checkbox
                                checked={type.indexOf(option.text) > -1}
                              />
                              <ListItemText primary={option.text} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
            <Box
              sx={{
                width: '100%',
                p: 1,
                border: 1,
                borderRadius: '10px',
                borderColor: 'grey.500',
                borderStyle: 'dashed',
              }}
            >
              <Grid
                container
                spacing={1}
                direction='column'
                justifyContent='center'
              >
                <Grid item xs={12} sm={12}>
                  <Controller
                    name='imageUrl'
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id='imageUrl'
                        label='Bildlänk'
                        fullWidth
                        multiline
                        margin='dense'
                        {...register('imageUrl')}
                        error={errors.imageUrl ? true : false}
                      />
                    )}
                  />
                  <Typography variant='inherit' color='textSecondary'>
                    {errors.imageUrl ? errors.imageUrl.message : ''}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={12} justifyContent='center'>
                  <CardMedia
                    component='img'
                    sx={{
                      height: '100%',
                      width: '100%',
                      maxHeight: { xs: 233, md: 300 },
                      maxWidth: { xs: 350, md: 300 },
                    }}
                    src={
                      meal.imageUrl === ''
                        ? 'https://storage.cloud.google.com/random-meal-driv-1596380337534.appspot.com/general-image.png?authuser=0'
                        : meal.imageUrl
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Stack>

          <Box
            mt={3}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              position: 'relative',
            }}
          >
            <Button
              variant='contained'
              color='primary'
              onClick={handleSubmit(onSubmit)}
            >
              Spara
            </Button>
            <Button
              variant='contained'
              color='error'
              onClick={() => navigate(-1)}
            >
              Avbryt
            </Button>
          </Box>
        </Box>
      </Paper>
    </>
  );
};

export default AddMealForm;
