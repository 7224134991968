import { Close, Warning } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import { IconButton, Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

export function Alert({
  title,
  message,
  actions,
  variant,
  verticalMargin,
  onClose,
}) {
  const { palette } = useTheme();

  const colors = useMemo(() => {
    switch (variant) {
      case 'warning':
        return {
          backgroundColor: palette.warning.background,
          borderColor: palette.warning.main,
        };
      case 'error':
        return {
          backgroundColor: palette.error.background,
          borderColor: palette.error.main,
        };
      case 'info':
        return {
          backgroundColor: palette.info.background,
          borderColor: palette.info.main,
        };
      default:
        return {
          backgroundColor: palette.success.background,
          borderColor: palette.success.main,
        };
    }
  }, [variant]);

  const Icon = useMemo(() => {
    switch (variant) {
      case 'success':
        return () => <CheckIcon color='success' />;
      case 'warning':
        return () => <Warning color='warning' />;
      case 'error':
        return () => <ErrorIcon color='error' />;
      case 'info':
        return () => <ErrorIcon color='info' />;
      default:
        return () => <CheckIcon color='success' />;
    }
  }, [variant]);

  return (
    <Box
      sx={{
        display: 'flex',
        padding: '0.75rem 1rem',
        paddingRight: '2.25em',
        margin: verticalMargin !== false ? '1rem 0rem' : '0',
        background: colors?.backgroundColor,
        borderColor: colors?.borderColor,
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '4px',
        gap: '0.75rem',
        position: 'relative',
      }}
    >
      <Icon />
      <Box sx={{ flexGrow: 1 }}>
        <Typography fontWeight={700}>{title && title}</Typography>
        <Typography>{message && message}</Typography>
        {actions && actions.length > 0 && (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            {actions.map((action) => (
              <Box
                component={Link}
                sx={{
                  color: palette.primary.main,
                  verticalAlign: 'bottom',
                }}
                key={action.title}
                {...action}
              >
                {action.title}
              </Box>
            ))}
          </Box>
        )}
      </Box>
      <Box
        sx={{
          position: 'absolute',
          right: '.25rem',
          top: '.25rem',
        }}
      >
        <IconButton onClick={onClose} size='small'>
          <Close />
        </IconButton>
      </Box>
    </Box>
  );
}
